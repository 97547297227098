var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-nicklist",class:{
    'kiwi-nicklist--filtering': _vm.filter_visible,
    'kiwi-nicklist--avatars': _vm.shouldShowAvatars
  }},[_c('div',{staticClass:"kiwi-nicklist-usercount"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_filter),expression:"user_filter"}],ref:"user_filter",attrs:{"placeholder":_vm.$t('filter_users')},domProps:{"value":(_vm.user_filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.user_filter=$event.target.value}}}),_c('i',{staticClass:"fa fa-search",attrs:{"aria-hidden":"true"}})]),_c('DynamicScroller',{staticClass:"kiwi-nicklist-users",attrs:{"items":_vm.sortedUsers,"min-item-size":34,"key-field":'nick'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
  var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[],"data-index":index}},[_c('nicklist-user',{key:item.nick,attrs:{"user":item,"nicklist":_vm.self,"network":_vm.network}})],1)]}}])}),_c('div',{staticClass:"cabrito-users"},[_c('div',[_c('i',{staticClass:"fa fa-users",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v(" "+_vm._s(_vm.sortedUsers.length)+" ")])]),_c('div',{staticClass:"cabrito-status"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }